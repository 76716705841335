import LANGUAGES from '@/configs/languages';

export enum Gender {
	MALE,
	FEMALE,
	BOTH,
}

export type Language = keyof typeof LANGUAGES;
export type ForeignLanguage = Exclude<Language, 'PL'>;

export type Order = 'asc' | 'desc';

export interface Sort {
	key: string | null;
	order: Order;
}
export interface HydraQueryOptions {
	page: number;
	sizePerPage: number;
	search?: string;
	sort: Sort;
}
export interface HydraResponse<TData extends {} = {}> {
	'hydra:member': TData[];
	'hydra:totalItems': number;
}
export interface HydraFormattedResponseData<TData extends {} = {}> {
	data: TData[];
	total: number;
}
export interface CreateManyResponse {
	passed: string[];
	rejected: string[];
}
export interface Attachment {
	id: string;
	originalName?: string;
	size?: string;
	contentUrl: string;
	mimeType: string;
}

export interface StringSignature<TData = any> {
	[key: string]: TData;
}

export enum NotificationMessageType {
	EMAIL,
	SMS,
	EMAIL_TEMPLATE,
}

export type OperatingSystem = 'ios' | 'android' | 'windows' | 'mac' | 'unknown';

export interface DeviceInfo {
	/**
	 * The name of the device. For example, "John's iPhone".
	 *
	 * This is only supported on iOS and Android 7.1 or above.
	 *
	 * On iOS 16+ this will return a generic device name without the appropriate [entitlements](https://developer.apple.com/documentation/bundleresources/entitlements/com_apple_developer_device-information_user-assigned-device-name).
	 *
	 * @since 1.0.0
	 */
	name?: string;
	/**
	 * The device model. For example, "iPhone13,4".
	 *
	 * @since 1.0.0
	 */
	model: string;
	/**
	 * The device platform (lowercase).
	 *
	 * @since 1.0.0
	 */
	platform: 'ios' | 'android' | 'web';
	/**
	 * The operating system of the device.
	 *
	 * @since 1.0.0
	 */
	operatingSystem: OperatingSystem;
	/**
	 * The version of the device OS.
	 *
	 * @since 1.0.0
	 */
	osVersion: string;
	/**
	 * The iOS version number.
	 *
	 * Only available on iOS.
	 *
	 * Multi-part version numbers are crushed down into an integer padded to two-digits, ex: `"16.3.1"` -> `160301`
	 *
	 * @since 5.0.0
	 */
	iOSVersion?: number;
	/**
	 * The Android SDK version number.
	 *
	 * Only available on Android.
	 *
	 * @since 5.0.0
	 */
	androidSDKVersion?: number;
	/**
	 * The manufacturer of the device.
	 *
	 * @since 1.0.0
	 */
	manufacturer: string;
	/**
	 * Whether the app is running in a simulator/emulator.
	 *
	 * @since 1.0.0
	 */
	isVirtual: boolean;
	/**
	 * Approximate memory used by the current app, in bytes. Divide by
	 * 1048576 to get the number of MBs used.
	 *
	 * @since 1.0.0
	 */
	memUsed?: number;
	/**
	 * How much free disk space is available on the normal data storage
	 * path for the os, in bytes.
	 *
	 * On Android it returns the free disk space on the "system"
	 * partition holding the core Android OS.
	 * On iOS this value is not accurate.
	 *
	 * @deprecated Use `realDiskFree`.
	 * @since 1.0.0
	 */
	diskFree?: number;
	/**
	 * The total size of the normal data storage path for the OS, in bytes.
	 *
	 * On Android it returns the disk space on the "system"
	 * partition holding the core Android OS.
	 *
	 * @deprecated Use `realDiskTotal`.
	 * @since 1.0.0
	 */
	diskTotal?: number;
	/**
	 * How much free disk space is available on the normal data storage, in bytes.
	 *
	 * @since 1.1.0
	 */
	realDiskFree?: number;
	/**
	 * The total size of the normal data storage path, in bytes.
	 *
	 * @since 1.1.0
	 */
	realDiskTotal?: number;
	/**
	 * The web view browser version
	 *
	 * @since 1.0.0
	 */
	webViewVersion: string;
}

export interface AppInfo {
	/**
	 * The name of the app.
	 *
	 * @since 1.0.0
	 */
	name: string;
	/**
	 * The identifier of the app.
	 * On iOS it's the Bundle Identifier.
	 * On Android it's the Application ID
	 *
	 * @since 1.0.0
	 */
	id: string;
	/**
	 * The build version.
	 * On iOS it's the CFBundleVersion.
	 * On Android it's the versionCode.
	 *
	 * @since 1.0.0
	 */
	build: string;
	/**
	 * The app version.
	 * On iOS it's the CFBundleShortVersionString.
	 * On Android it's package's versionName.
	 *
	 * @since 1.0.0
	 */
	version: string;
}
