import React, { useContext, ReactNode, useLayoutEffect, useState } from 'react';
import { Admin, Role, User, UserStatus } from '@/types/user';
import { LoginValues } from '@/pages/unauthenticated/Login';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { Group } from '@/api/GroupApi';
import { Attachment } from '@/types/types';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/clients/httpClient';
import Cookies from 'js-cookie';

export enum StorageKeys {
	REFRESH_TOKEN = 'refresh_token',
}

interface TokenPayload extends JwtPayload {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	group: Group;
	roles: string[];
	status: UserStatus;
	avatar: string | File | null | Attachment;
}

interface Context {
	user: User | null;
	changeUser: (newUser: User | null) => void;
	login: (values: LoginValues) => Promise<number>;
	logout: () => void;
	requestSMS: (
		values: Omit<LoginValues, 'remember' & 'code'>
	) => Promise<boolean | { verification: boolean; code?: string }>;
}

const AuthContext = React.createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [user, setUser] = useLocalStorage<User | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [refreshToken, setRefreshToken, clearRefreshToken] = useSessionStorage<
		string | undefined
	>({
		key: StorageKeys.REFRESH_TOKEN,
		defaultValue: Cookies.get(StorageKeys.REFRESH_TOKEN),
		getInitialValueInEffect: false,
	});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken(refreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			setUser(null);
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			const isAdmin = decoded.roles.includes(Role.ADMIN);
			if (!isAdmin) {
				logout();
				throw new Error('Not an admin');
			}
			const remember = !!Cookies.get(StorageKeys.REFRESH_TOKEN);

			setAuth(decoded, token, refresh_token, remember);

			const user = mapTokenToUser(decoded);

			setUser(user);
		} catch (error: any) {
			console.error(error);
			if (error.message !== 'Request aborted') setUser(null);
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload, refresh: string) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const requestSMS = async ({
		email,
		password,
	}: Omit<LoginValues, 'remember' & 'code'>) => {
		try {
			const response = await UnauthenticatedApi.requestSMSCode({
				email,
				password,
			});

			if (response.status === 400) throw new Error();

			return response.data;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const login = async ({ email, password, smsCode, remember }: LoginValues) => {
		try {
			const response = await UnauthenticatedApi.login({
				email,
				password,
				smsCode,
			});

			if (response.status !== 200)
				return response.data.insideExceptionCode || 0;

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			const isAdmin = decoded.roles.includes(Role.ADMIN);
			if (!isAdmin) throw new Error('Not an admin');

			setAuth(decoded, token, refresh_token, remember);

			const user = mapTokenToUser(decoded);

			setTimeout(() => {
				setUser(user);
			}, 1100);

			return 1;
		} catch (error) {
			console.error(error);
			return 0;
		}
	};

	const logout = () => {
		queryClient.cancelQueries();
		Cookies.remove(StorageKeys.REFRESH_TOKEN);
		httpClient.defaults.headers.common['Authorization'] = '';
		clearRefreshToken();
		setUser(null);
		queryClient.clear();
		clearTimeout(refreshTimeout);
	};

	const saveRefreshToken = (token: string) => {
		setRefreshToken(token);
		Cookies.set('refresh_token', token, {
			secure: true,
			expires: 360,
			sameSite: 'lax',
		});
	};

	const setAuth = (
		decoded: TokenPayload,
		token: string,
		refresh: string,
		remember = true
	) => {
		refreshTokenWhenExpire(decoded, refresh);

		setAuthHeader(token);

		if (remember) saveRefreshToken(refresh);
		else setRefreshToken(refresh);
	};

	const changeUser: Context['changeUser'] = (newUser) => setUser(newUser);

	return (
		<AuthContext.Provider
			value={{ user, changeUser, login, logout, requestSMS }}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function mapTokenToUser(decodedToken: TokenPayload): Admin {
	return {
		id: decodedToken.id,
		firstName: decodedToken.firstName,
		lastName: decodedToken.lastName,
		email: decodedToken.email,
		phone: decodedToken.phone,
		group: decodedToken.group,
		role: Role.ADMIN,
		status: decodedToken.status,
		avatar: decodedToken.avatar
			? { id: '', contentUrl: `/uploads/${decodedToken.avatar}`, mimeType: '' }
			: null,
	};
}
